<template>  
  <div>  
    <el-switch  
      v-model="isUsingNewDashboard"  
      active-text="New Dashboard"  
      inactive-text="Old Dashboard"  
    />  
 
    <div v-if="!isUsingNewDashboard">   
      <OldDashboard />  
    </div>  
  
    <div v-else>
      <NewDashboard />  
    </div>

  </div>  
</template>

<script>  
  import OldDashboard from './OldDashboard.vue';  
  import NewDashboard from './NewDashboard.vue';  
    
  export default {  
    components: {  
      OldDashboard,  
      NewDashboard  
    },  
    data() {  
      return {
        isUsingNewDashboard: JSON.parse(localStorage.getItem('isUsingNewDashboard')) ?? true  
      };  
    },  
    watch: {
      isUsingNewDashboard(newValue) {  
        localStorage.setItem('isUsingNewDashboard', newValue);  
      }  
    }  
  };  
</script> 
